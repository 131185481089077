<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      style="box-shadow: none; border-bottom: 1px solid grey !important"
    >
      <div class="d-flex align-center">

        <v-img src="~@/assets/logomod.svg"
               class="shrink mr-2"
               contain
               transition="scale-transition"
               width="80"
        />


      </div>

      <v-spacer></v-spacer>

      <v-btn
          v-if="is_logged_in"
        text
          @click="logout"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({

    is_logged_in : false,
    cookie_name : "secret"
    //
  }),
  mounted() {

    if(this.$cookies.get(this.cookie_name))
    {
      this.is_logged_in = true
      this.$router.push("/dashboard")

    }
    else{
      this.$router.push("/login")
    }

  },

  methods : {
    logout(){
      this.$cookies.remove(this.cookie_name)
      window.location.reload()

    }
  }
};
</script>
