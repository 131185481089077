<template>

  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="5">
        <v-card-text class="card_view">
  <div>
    <h3>Please enter your token</h3>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-text-field
          v-model="secret"
          color="secondary"
          label="Please enter your token"
          :rules="[rules.required]"
      ></v-text-field>


      <v-btn
          :disabled="!valid"
          :dark="valid"
          rounded
          block
          class="mt-3"
          @click="save_token"
      >
        Log in
      </v-btn>
    </v-form>

  </div>

        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LoginComp',

    data: () => ({
      valid: true,
      lazy: false,
      cookie_name : "secret",

      rules: {
        required: value => !!value || 'Required.'
      },
      secret : ""
    }),
    methods : {
      save_token(){
        this.$cookies.set(this.cookie_name, this.secret,60 * 60 * 24 * 5,"/")
        window.location.reload()
      }
    }
  }
</script>

<style scoped>
.card_view {

  box-shadow: 0px 0px 4px 0px #111111;
  border-radius: 10px;
  padding-top: 50px ;
  padding-bottom: 50px ;
}
</style>
